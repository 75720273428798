import React, { useState, useEffect } from "react";
import { Container, Row, Modal, Button, Col } from "react-bootstrap";
import Masonry from "react-masonry-css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faFaceGrinStars,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const About = () => {
  const windowHeight = window.innerHeight;
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const localVideos = [
    "/videos/1.mp4", 
    "/videos/2.mp4",
    "/videos/3.mp4",
    "/videos/4.mp4"
  ];

  const imageNames = [
    "23",
    "21",
    "43",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "37",
    "13",
    "35",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "36",
    "20",
    "22",
    "24",
    "25",
    "2",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "45",
    "40",
    "41",
    "38",
    "39",
    "1",
    "42",
    "44",
    "46",
  ];
  const images = imageNames.map((name) => `/img/gallery/${name}.png`);

  useEffect(() => {
    const reveal = (className) => {
      var reveals = document.querySelectorAll(className);

      for (var i = 0; i < reveals.length; i++) {
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    };

    window.addEventListener("scroll", () => {
      reveal(".reveal");
      reveal(".reveal2");
    });

    // Initial reveal
    reveal(".reveal");
    reveal(".reveal2");

    // Cleanup
    return () => {
      window.removeEventListener("scroll", () => {
        reveal(".reveal");
        reveal(".reveal2");
      });
    };
  }, [windowHeight]);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Breakpoint configuration for react-masonry-css
  const breakpointColumnsObj = {
    default: 6,
    1100: 4,
    700: 3,
    500: 2,
  };

  return (
    <>
      <Helmet>
        <title>Galeria | Printly</title>
        <meta
          name="description"
          content="Zapoznaj się z naszymi pracami, przejrzyj naszą galerię zdjęć."
        />
        <link rel="canonical" href="https://printly.net.pl/about" />
      </Helmet>

      <div className="item container about">
        <Container fluid className="p-5">
          <h3 className="header-center-big ">Nasze Realizacje</h3>
          <p className="text-center ">
            Zapraszamy do obejrzenia galerii naszych wykonanych prac! W Printly
            tworzymy wysokiej jakości materiały reklamowe, takie jak banery,
            naklejki na samochody, oznakowanie witryn, roll-upy i wiele innych.
            Każdy projekt to indywidualne podejście i dbałość o detale, aby
            zapewnić trwałość oraz estetyczny wygląd. Sprawdź nasze realizacje i
            przekonaj się, jak możemy pomóc w promocji Twojej marki!
          </p><br/>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="gallery-masonry-grid"
            columnClassName="gallery-masonry-column"
          >
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Gallery item ${index + 1}`}
                className="gallery-image"
                onClick={() => openModal(index)}
              />
            ))}
          </Masonry>
          <Container className="mt-5">
            <h3 className="header-center-big ">Zobacz jak pracujemy!</h3>
            <Row className="justify-content-center">
              {localVideos.map((video, index) => (
                <Col key={index} md={6} className="mb-4">
                  <div className="video-container">
                    <video src={video} muted playsInline loop autoPlay className="local-video">
                      Twoja przeglądarka nie wspiera odtwarzania wideo.
                    </video>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </Container>

        <Modal
          show={showModal}
          onHide={closeModal}
          centered
          dialogClassName="full-screen-modal"
        >
          <Modal.Body className="text-center">
            <Button className="close-modal-button" onClick={closeModal}>
              &times;
            </Button>
            <Button className="prev-arrow" onClick={handlePrevImage}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <img
              src={images[currentImageIndex]}
              alt={`Gallery item ${currentImageIndex + 1}`}
              className="img-fluid"
            />
            <Button className="next-arrow" onClick={handleNextImage}>
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default About;
