import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const Services = () => {
  const windowHeight = window.innerHeight;

  useEffect(() => {
    const reveal = (className) => {
      var reveals = document.querySelectorAll(className);

      for (var i = 0; i < reveals.length; i++) {
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    };

    window.addEventListener("scroll", () => {
      reveal(".reveal");
      reveal(".reveal2");
    });

    // Initial reveal
    reveal(".reveal");
    reveal(".reveal2");
    // Cleanup
    return () => {
      window.removeEventListener("scroll", () => {
        reveal(".reveal");
        reveal(".reveal2");
      });
    };
  }, [windowHeight]);

  return (
    <>
      <Helmet>
        <title>Usługi | Printly</title>
        <meta
          name="description"
          content="Zapoznaj się z naszymi usługami, skorzystaj z odnośników do kalkulatorów cen."
        />
        <link rel="canonical" href="https://printly.net.pl/services" />
      </Helmet>

      <div className="item item-services container">
        <h3 className="header-center-big mb-5 pt-5"> PRODUKTY </h3>
        <Row className="text-center">
          <Col sm={6} md={4} className="p-4">
            <img src="img/baner2.png" className="img-fluid bor" alt="baner" />
            <div className="item-body">
              <h5 className="item-title">BANERY</h5>
              <p className="item-text">
                Banery są idealnym nośnikiem reklamowym na imprezy plenerowe,
                wystawy, czy jako element dekoracyjny. Możesz także umieścić je
                na ścianach budynków lub płotach by przyciągnąć uwagę większej
                liczby klientów.
              </p>
            </div>
            <Link to="/baners">
              <button className="mt-auto">BANERY</button>
            </Link>
          </Col>

          <Col sm={6} md={4} className="p-4">
            <img
              src="img/sticker-window.png"
              className="img-fluid bor"
              alt="baner"
            />
            <div className="item-body">
              <h5 className="item-title">FOLIE REKLAMOWE</h5>
              <p className="item-text">
                Monomeryczne lub polimeryczne folie wykorzystywane do reklamy
                wewnętrznej lub zewnętrznej, idealne do oklejania witryn
                sklepowych, samochodów, szyb, ścian, podłóg, mebli, drzwi, a
                także do oznakowania tablic.
              </p>
            </div>
            <Link to="/foil">
              <button className="mt-auto">FOLIE REKLAMOWE</button>
            </Link>
          </Col>

          <Col sm={6} md={4} className="p-4">
            <img src="img/car.jpg" className="img-fluid bor" alt="baner" />
            <div className="item-body">
              <h5 className="item-title">NAKLEJKI</h5>
              <p className="item-text">
                Personalizowane naklejki to doskonały sposób na promocję marki
                lub produktu. Wykonane z trwałego materiału, mogą być stosowane
                na różnorodnych powierzchniach, od samochodów po opakowania,
                dodając profesjonalizmu i charakteru Twojemu biznesowi.
              </p>
            </div>
            <Link to="/stickers">
              <button className="mt-auto">NAKLEJKI</button>
            </Link>
          </Col>

          <Col sm={6} md={4} className="p-4">
            <img src="img/a-board.jpg" className="img-fluid bor" alt="baner" />
            <div className="item-body">
              <h5 className="item-title">POTYKACZE</h5>
              <p className="item-text">
                Drukowane potykacze to mobilne tablice reklamowe, które z
                łatwością przyciągają uwagę przechodniów i klientów. Idealne do
                ustawienia przed sklepem lub na targach, potykacze pozwalają
                wyróżnić Twoją ofertę w tłumie konkurencyjnych firm.
              </p>
            </div>
            <Link to="/aboards">
              <button className="mt-auto">POTYKACZE</button>
            </Link>
          </Col>

          <Col sm={6} md={4} className="p-4">
            <img src="img/roll-up.png" className="img-fluid bor" alt="baner" />
            <div className="item-body">
              <h5 className="item-title">ROLL-UPY</h5>
              <p className="item-text">
                Nasze roll-upy to praktyczne i eleganckie systemy ekspozycji,
                które można łatwo złożyć i przenosić. Drukowane z najwyższą
                jakością, roll-upy są idealnym dodatkiem na prezentacje,
                konferencje, czy eventy firmowe, skutecznie prezentując Twoje
                produkty lub usługi.
              </p>
            </div>
            <Link to="/rollups">
              <button className="mt-auto">ROLL-UPY</button>
            </Link>
          </Col>

          <Col sm={6} md={4} className="p-4">
            <img src="img/komura.png" className="img-fluid bor" alt="baner" />
            <div className="item-body">
              <h5 className="item-title">MAGNESY</h5>
              <p className="item-text">
                Magnesy świetnie nadadzą się do personalizacji Twojego samochodu
                bez konieczności trwałych zmian. Łatwo przymocuj i zdejmij
                magnesy w dowolnej chwili, bez ryzyka uszkodzeń czy
                pozostawiania śladów.
              </p>
            </div>
            <Link to="/magnets">
              <button className="mt-auto">MAGNESY</button>
            </Link>
          </Col>

          <Col sm={6} md={4} className="p-4">
            <img src="img/poster2.png" className="img-fluid bor" alt="baner" />
            <div className="item-body">
              <h5 className="item-title">PLAKATY</h5>
              <p className="item-text">
                Z naszym drukiem plakatów możesz przyciągnąć uwagę klientów.
                Dostępne w różnych formatach, plakaty są skutecznym narzędziem
                marketingowym zarówno dla małych biznesów, jak i dużych firm.
              </p>
            </div>
            <Link to="/posters">
              <button className="mt-auto">PLAKATY</button>
            </Link>
          </Col>

          <Col sm={6} md={4} className="p-4">
            <img
              src="img/bussiness-card.png"
              className="img-fluid bor"
              alt="baner"
            />
            <div className="item-body">
              <h5 className="item-title">WIZYTÓWKI</h5>
              <p className="item-text">
                Wizytówki to klasyczny sposób na profesjonalne przedstawienie
                się i nawiązanie kontaktu. Dzięki ich kompaktowemu formatowi
                łatwo je przechować i szybko wręczyć, pozostawiając trwałe
                wrażenie.
              </p>
            </div>
            <Link to="/bussiness-card">
              <button className="mt-auto">WIZYTÓWKI</button>
            </Link>
          </Col>

          <Col sm={6} md={4} className="p-4">
            <img src="img/flyer.png" className="img-fluid bor" alt="baner" />
            <div className="item-body">
              <h5 className="item-title">ULOTKI</h5>
              <p className="item-text">
                Ulotki to efektywna forma reklamy, która pozwala na szybkie
                dotarcie z informacjami do szerokiego grona odbiorców. Dzięki
                atrakcyjnej grafice i przemyślanej treści, ulotki są idealne do
                promowania produktów, usług, wydarzeń czy akcji marketingowych.
              </p>
            </div>
            <Link to="/flyer">
              <button className="mt-auto">ULOTKI</button>
            </Link>
          </Col>
        </Row>
      </div>

      {/*
      {isMobile ? (
        <div className="about">
          <Container className="card-container">
          <h3 className="header-center-big mb-5 services-h3"> PRODUKTY </h3>
            <Row className="m-5 card-one">
              <Col sm={4}>
                <img
                  src="img/baner2.png"
                  className="img-fluid rounded-left"
                  alt="baner"
                />
              </Col>
              <Col sm={8}>
                <div className="card-body d-flex flex-column justify-content-between h-100">
                  <h5 className="card-title">BANERY</h5>
                  <p className="card-text">
                    Banery są idealnym nośnikiem reklamowym na imprezy plenerowe,
                    wystawy, czy jako element dekoracyjny. Możesz także umieścić je
                    na ścianach budynków lub płotach by przyciągnąć uwagę większej
                    liczby klientów.
                  </p>
                  <button className="mt-auto">
                    <Link to="/baners">BANERY</Link>
                  </button>
                </div>
              </Col>
            </Row>

            <Row className="m-5 card-one reveal">
              <Col sm={4}>
                <img
                  src="img/sticker-window.png"
                  className="img-fluid rounded-left"
                  alt="roll-up"
                />
              </Col>
              <Col sm={8}>
                <div className="card-body d-flex flex-column justify-content-between h-100">
                  <h5 className="card-title">FOLIE REKLAMOWE</h5>
                  <p className="card-text">
                    Monomeryczne lub polimeryczne folie wykorzystywane do reklamy wewnętrznej lub zewnętrznej, idealne do oklejania witryn sklepowych, samochodów, szyb, ścian, podłóg, mebli, drzwi, a także do 
                    oznakowania tablic.
                  </p>
                  <button className="mt-auto">
                    <Link to="/foil">FOLIE REKLAMOWE</Link>
                  </button>
                </div>
              </Col>
            </Row>

            <Row className="m-5 card-one reveal">
              <Col sm={4}>
                <img
                  src="img/car.jpg"
                  className="img-fluid rounded-left"
                  alt="sticker"
                />
              </Col>
              <Col sm={8}>
                <div className="card-body d-flex flex-column justify-content-between h-100">
                  <h5 className="card-title">NAKLEJKI</h5>
                  <p className="card-text">
                    Personalizowane naklejki to doskonały sposób na promocję marki
                    lub produktu. Wykonane z trwałego materiału, mogą być stosowane
                    na różnorodnych powierzchniach, od samochodów po opakowania,
                    dodając profesjonalizmu i charakteru Twojemu biznesowi.
                  </p>
                  <button className="mt-auto">
                    <Link to="/stickers">NAKLEJKI</Link>
                  </button>
                </div>
              </Col>
            </Row>

            <Row className="m-5 card-one reveal2">
              <Col sm={4}>
                <img
                  src="img/a-board.jpg"
                  className="img-fluid rounded-right"
                  alt="a-board"
                />
              </Col>
              <Col sm={8}>
                <div className="card-body d-flex flex-column justify-content-between h-100">
                  <h5 className="card-title">POTYKACZE</h5>
                  <p className="card-text">
                    Drukowane potykacze to mobilne tablice reklamowe, które z
                    łatwością przyciągają uwagę przechodniów i klientów. Idealne do
                    ustawienia przed sklepem lub na targach, potykacze pozwalają
                    wyróżnić Twoją ofertę w tłumie konkurencyjnych firm.
                  </p>
                  <button className="mt-auto">
                    <Link to="/aboards">POTYKACZE</Link>
                  </button>
                </div>
              </Col>
              
            </Row>

            <Row className="m-5 card-one reveal">
              <Col sm={4}>
                <img
                  src="img/roll-up.png"
                  className="img-fluid rounded-left"
                  alt="roll-up"
                />
              </Col>
              <Col sm={8}>
                <div className="card-body d-flex flex-column justify-content-between h-100">
                  <h5 className="card-title">ROLL-UPY</h5>
                  <p className="card-text">
                    Nasze roll-upy to praktyczne i eleganckie systemy ekspozycji,
                    które można łatwo złożyć i przenosić. Drukowane z najwyższą
                    jakością, roll-upy są idealnym dodatkiem na prezentacje,
                    konferencje, czy eventy firmowe, skutecznie prezentując Twoje
                    produkty lub usługi.
                  </p>
                  <button className="mt-auto">
                    <Link to="/rollups">ROLL-UPY</Link>
                  </button>
                </div>
              </Col>
            </Row>

            <Row className="m-5 card-one reveal2">
            <Col sm={4}>
                <img
                  src="img/komura.png"
                  className="img-fluid rounded-right"
                  alt="magnets"
                />
              </Col>
              <Col sm={8}>
                <div className="card-body d-flex flex-column justify-content-between h-100">
                  <h5 className="card-title">MAGNESY</h5>
                  <p className="card-text">
                    Magnesy świetnie nadadzą się do personalizacji Twojego samochodu
                    bez konieczności trwałych zmian. Łatwo przymocuj i zdejmij
                    magnesy w dowolnej chwili, bez ryzyka uszkodzeń czy
                    pozostawiania śladów.
                  </p>
                  <button className="mt-auto">
                    <Link to="/magnets">MAGNESY</Link>
                  </button>
                </div>
              </Col>
              
            </Row>

            <Row className="m-5 card-one reveal2">
            <Col sm={4}>
                <img
                  src="img/poster2.png"
                  className="img-fluid rounded-right"
                  alt="poster"
                />
              </Col>
              <Col sm={8}>
                <div className="card-body d-flex flex-column justify-content-between h-100">
                  <h5 className="card-title">PLAKATY</h5>
                  <p className="card-text">
                    Z naszym drukiem plakatów możesz przyciągnąć uwagę klientów.
                    Dostępne w różnych formatach, plakaty są skutecznym narzędziem
                    marketingowym zarówno dla małych biznesów, jak i dużych firm.
                  </p>
                  <button className="mt-auto">
                    <Link to="/posters">PLAKATY</Link>
                  </button>
                </div>
              </Col>
              
            </Row>

          </Container>
        </div>) : (
        <>
          <Container className="price-list-mobile">
            <Row>
              <h3 className="header-center-big my-5"> PRODUKTY </h3>

              <Col className="price-list-col-mobile mb-4">
              <img
                  src="img/baner2.png"
                  className="img-fluid rounded"
                  alt="baner"
                />
                <div className="card-body d-flex flex-column justify-content-between h-100">
                  <h5 className="card-title text-center">BANERY</h5>
                  <p className="card-text">
                    Banery są idealnym nośnikiem reklamowym na imprezy plenerowe,
                    wystawy, czy jako element dekoracyjny. Możesz także umieścić je
                    na ścianach budynków lub płotach by przyciągnąć uwagę większej
                    liczby klientów.
                  </p>
                  <button className="mt-auto">
                    <Link to="/baners">Kalkulator cen</Link>
                  </button>
                </div>
              </Col>

              <Col className="price-list-col-mobile mb-4 reveal">
              <img
                  src="img/sticker-window.png"
                  className="img-fluid rounded"
                  alt="magnets"
                />
                <div className="card-body d-flex flex-column justify-content-between h-100">
                  <h5 className="card-title text-center">FOLIE REKLAMOWE</h5>
                  <p className="card-text">
                  Monomeryczne lub polimeryczne folie wykorzystywane do reklamy wewnętrznej lub zewnętrznej, idealne do oklejania witryn sklepowych, samochodów, szyb, ścian, podłóg, mebli, drzwi, a także do 
                  oznakowania tablic.
                  </p>
                  <button className="mt-auto">
                    <Link to="/foil">Kalkulator cen</Link>
                  </button>
                </div>
              </Col>

              <Col className="price-list-col-mobile mb-4 reveal">
              <img
                  src="img/car.jpg"
                  className="img-fluid rounded"
                  alt="sticker"
                />
                <div className="card-body d-flex flex-column justify-content-between h-100">
                  <h5 className="card-title text-center">NAKLEJKI</h5>
                  <p className="card-text">
                    Personalizowane naklejki to doskonały sposób na promocję marki
                    lub produktu. Wykonane z trwałego materiału, mogą być stosowane
                    na różnorodnych powierzchniach, od samochodów po opakowania,
                    dodając profesjonalizmu i charakteru Twojemu biznesowi.
                  </p>
                  <button className="mt-auto">
                    <Link to="/stickers">Kalkulator cen</Link>
                  </button>
                </div>
              </Col>

              <Col className="price-list-col-mobile mb-4 reveal">
              <img
                  src="img/a-board.jpg"
                  className="img-fluid rounded"
                  alt="a-board"
                />
                <div className="card-body d-flex flex-column justify-content-between h-100">
                  <h5 className="card-title text-center">POTYKACZE</h5>
                  <p className="card-text">
                    Drukowane potykacze to mobilne tablice reklamowe, które z
                    łatwością przyciągają uwagę przechodniów i klientów. Idealne do
                    ustawienia przed sklepem lub na targach, potykacze pozwalają
                    wyróżnić Twoją ofertę w tłumie konkurencyjnych firm.
                  </p>
                  <button className="mt-auto">
                    <Link to="/aboards">Kalkulator cen</Link>
                  </button>
                </div>
              </Col>

              <Col className="price-list-col-mobile mb-4 reveal">
              <img
                  src="img/roll-up.png"
                  className="img-fluid rounded"
                  alt="roll-up"
                />
                <div className="card-body d-flex flex-column justify-content-between h-100">
                  <h5 className="card-title text-center">ROLL-UPY</h5>
                  <p className="card-text">
                    Nasze roll-upy to praktyczne i eleganckie systemy ekspozycji,
                    które można łatwo złożyć i przenosić. Drukowane z najwyższą
                    jakością, roll-upy są idealnym dodatkiem na prezentacje,
                    konferencje, czy eventy firmowe, skutecznie prezentując Twoje
                    produkty lub usługi.
                  </p>
                  <button className="mt-auto">
                    <Link to="/rollups">Kalkulator cen</Link>
                  </button>
                </div>
              </Col>

              <Col className="price-list-col-mobile mb-4 reveal">
              <img
                  src="img/komura.png"
                  className="img-fluid rounded"
                  alt="magnets"
                />
                <div className="card-body d-flex flex-column justify-content-between h-100">
                  <h5 className="card-title text-center">MAGNESY</h5>
                  <p className="card-text">
                    Magnesy świetnie nadadzą się do personalizacji Twojego samochodu
                    bez konieczności trwałych zmian. Łatwo przymocuj i zdejmij
                    magnesy w dowolnej chwili, bez ryzyka uszkodzeń czy
                    pozostawiania śladów.
                  </p>
                  <button className="mt-auto">
                    <Link to="/magnets">Kalkulator cen</Link>
                  </button>
                </div>
              </Col>
              
              <Col className="price-list-col-mobile mb-4 reveal">
              <img
                  src="img/poster2.png"
                  className="img-fluid rounded"
                  alt="poster"
                />
                <div className="card-body d-flex flex-column justify-content-between h-100">
                  <h5 className="card-title text-center">PLAKATY</h5>
                  <p className="card-text">
                    Z naszym drukiem plakatów możesz przyciągnąć uwagę klientów.
                    Dostępne w różnych formatach, plakaty są skutecznym narzędziem
                    marketingowym zarówno dla małych biznesów, jak i dużych firm.
                  </p>
                  <button className="mt-auto">
                    <Link to="/posters">Kalkulator cen</Link>
                  </button>
                </div>
              </Col>
              
            </Row>
          </Container>
         </>)} */}
    </>
  );
};
export default Services;
