import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faFileSignature, faPhone } from "@fortawesome/free-solid-svg-icons";

const Posters = () => {
  // const [isMobile, setIsMobile] = useState(window.innerWidth > 990);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth > 990);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   // Cleanup
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  return (
    <>
      <Helmet>
        <title>Plakaty | Printly</title>
        <meta
          name="description"
          content="Zapoznaj się z ofertą plakatów reklamowych, oblicz cenę korzystając z kalkulatora."
        />
        <link rel="canonical" href="https://printly.net.pl/posters" />
      </Helmet>

      <div className="item">
        <Row>
          <Col md={5}>
            <img src="/img/poster2.png" className="img-fluid" alt="baner" />
            <div className="item-contact text-center">
              <b>
                Aby poznać cenę Twojego zamówienia, skontaktuj się z nami
                poprzez formularz kontaktowy, Facebooka lub e-mail.{" "}
              </b>
              <br></br>
              <hr></hr>
              <br></br>
              <div className="item-contact-buttons ">
                <Row>
                  <Col sm={6}>
                    <FontAwesomeIcon icon={faFileSignature} /> <br></br>
                    <Link to="/contact" className="link">
                      FORMULARZ KONTAKTOWY
                    </Link>{" "}
                  </Col>
                  <Col sm={6}>
                    <FontAwesomeIcon icon={faEnvelope} /> <br></br>
                    <Link to="mailto:printlypl@gmail.com" className="link">
                      EMAIL: PRINTLYPL@GMAIL.COM
                    </Link>{" "}
                  </Col>
                  <Col sm={6}>
                    <FontAwesomeIcon icon={faFacebookMessenger} />
                    <br></br>{" "}
                    <Link to="https://m.me/Printlypl" className="link">
                      FACEBOOK: WWW.FB.COM/PRINTLYPL
                    </Link>
                  </Col>
                  <Col sm={6}>
                    <FontAwesomeIcon icon={faPhone} />
                    <br></br>{" "}
                    <Link to="tel:575-254-057" className="link">
                      +48 575-254-057
                    </Link>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={7}>
            <div className="item-description">
              <h5 className="card-title mt-4">PLAKATY</h5>
              <p className="card-text-2">
                Plakaty to jedna z najstarszych, ale wciąż bardzo skutecznych
                form reklamy wizualnej. Dzięki ich dużym formatom i atrakcyjnej
                grafice, przyciągają uwagę odbiorców w każdym miejscu – od ścian
                w biurach, przez przestrzenie publiczne, aż po ogłoszenia na
                wydarzeniach czy wystawach. Nasza drukarnia oferuje plakaty,
                które wyróżniają się doskonałą jakością druku i trwałością,
                dostosowane do różnych potrzeb.
              </p>
              <p className="card-text-2">
                <ul>
                  <li>
                    <strong>Reklama i marketing</strong> – promowanie produktów,
                    usług i wydarzeń.
                  </li>
                  <li>
                    <strong>Informacje publiczne</strong> – przekazywanie
                    ważnych komunikatów w miejscach publicznych.
                  </li>
                  <li>
                    <strong>Plakaty edukacyjne</strong> – wykorzystywane w
                    szkołach, uczelniach i instytucjach edukacyjnych.
                  </li>
                  <li>
                    <strong>Plakaty filmowe</strong> – promowanie premier
                    kinowych i spektakli.
                  </li>
                  <li>
                    <strong>Plakaty koncertowe i eventowe</strong> – zapowiedzi
                    wydarzeń muzycznych, festiwali, targów.
                  </li>
                  <li>
                    <strong>Plakaty dekoracyjne</strong> – ozdoba wnętrz w
                    domach, biurach i kawiarniach.
                  </li>
                  <li>
                    <strong>Plakaty polityczne i społeczne</strong> – kampanie
                    wyborcze, akcje społeczne, protesty.
                  </li>
                  <li>
                    <strong>Plakaty kolekcjonerskie</strong> – limitowane edycje
                    dla kolekcjonerów i fanów sztuki.
                  </li>
                </ul>
              </p>
              <p className="card-text-2">
                <b>
                  W przypadku pytań prosimy o{" "}
                  <Link to="/contact" className="link">
                    kontakt
                  </Link>
                  . Dziękujemy za zainteresowanie naszą ofertą i mamy nadzieję,
                  że wspólnie znajdziemy idealne rozwiązanie dla Twoich potrzeb!
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </div>

      {/*
      {isMobile ? (
        <div className="about">
          <Container className="card-container">
            <Row className="m-5 card-one">
              <Col md={6}>
                <img
                  src="/img/poster2.png"
                  className="img-fluid rounded-left-top"
                  alt="..."
                />
                <h5 className="card-title mt-4">PLAKATY</h5>
                <p className="card-text-2">
                Plakaty to jedna z najstarszych, ale wciąż bardzo skutecznych form 
                reklamy wizualnej. Dzięki ich dużym formatom i atrakcyjnej grafice, 
                przyciągają uwagę odbiorców w każdym miejscu – od ścian w biurach, 
                przez przestrzenie publiczne, aż po ogłoszenia na wydarzeniach czy 
                wystawach. Nasza drukarnia oferuje plakaty, które wyróżniają się doskonałą 
                jakością druku i trwałością, dostosowane do różnych potrzeb. 
                </p>
                
                <p className="card-text-2">
                  <b>
                    W przypadku pytań lub potrzeby indywidualnej pomocy prosimy
                    o{" "}
                    <Link to="/contact" className="link">
                      kontakt
                    </Link>
                    . Dziękujemy za zainteresowanie naszą ofertą i mamy
                    nadzieję, że wspólnie znajdziemy idealne rozwiązanie dla
                    Twoich potrzeb!
                  </b>
                </p>
              </Col>

              <Col md={6}>
              <div>
                    <br></br>
                    <b>Naklejki tego typu wyceniamy indywidualnie 
                      po skontaktowaniu się z nami. </b>
                      <p className="baners-list pt-4">
                            W celu
                            uzyskania dokładnej oferty prosimy o{" "}
                            <Link to="/contact" className="link">
                              kontakt
                            </Link>
                            .
                          </p>
                   </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <>
          <Container className="price-list-mobile">
            <Row>
              <Col className="price-list-col-mobile">
                <img
                  src="/img/poster2.png"
                  className="img-fluid rounded"
                  alt="..."
                />
                <h3 className="header-center-big mb-5">PLAKATY</h3>
                <span className="scroll-down">
                  <b>
                    Zjedź na dół by skorzystać z kalkulatora
                    cen.&nbsp;&nbsp;&nbsp;&nbsp;
                  </b>
                  <a className="arrow-to" href="#here">
                    <FontAwesomeIcon icon={faCircleArrowDown} />
                  </a>
                </span>
                <p className="card-text-2">
                Plakaty to jedna z najstarszych, ale wciąż bardzo skutecznych form 
                reklamy wizualnej. Dzięki ich dużym formatom i atrakcyjnej grafice, 
                przyciągają uwagę odbiorców w każdym miejscu – od ścian w biurach, 
                przez przestrzenie publiczne, aż po ogłoszenia na wydarzeniach czy 
                wystawach. Nasza drukarnia oferuje plakaty, które wyróżniają się doskonałą 
                jakością druku i trwałością, dostosowane do różnych potrzeb. 
                </p>
                <p className="card-text-2">
                  <b>
                    W przypadku pytań lub potrzeby indywidualnej pomocy prosimy
                    o{" "}
                    <Link to="/contact" className="link">
                      kontakt
                    </Link>
                    . Dziękujemy za zainteresowanie naszą ofertą i mamy
                    nadzieję, że wspólnie znajdziemy idealne rozwiązanie dla
                    Twoich potrzeb!
                  </b>
                </p>
                <hr className="my-4" />
                <div>
                    <br></br>
                    <b>Naklejki tego typu wyceniamy indywidualnie 
                      po skontaktowaniu się z nami. </b>
                      <p className="baners-list pt-4">
                            W celu
                            uzyskania dokładnej oferty prosimy o{" "}
                            <Link to="/contact" className="link">
                              kontakt
                            </Link>
                            .
                          </p>
                   </div>
              </Col>
            </Row>
          </Container>
        </>
      )}{" "} */}
    </>
  );
};

export default Posters;
