import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faFileSignature, faPhone } from "@fortawesome/free-solid-svg-icons";

const Flyer = () => {
  return (
    <>
      <Helmet>
        <title>Ulotki | Printly</title>
        <meta
          name="description"
          content="Zapoznaj się z ofertą ulotek, oblicz cenę korzystając z kalkulatora."
        />
        <link rel="canonical" href="https://printly.net.pl/flyer" />
      </Helmet>

      <div className="item">
        <Row>
          <Col md={5}>
            <img src="/img/flyer.png" className="img-fluid" alt="baner" />
            <div className="item-contact text-center">
              <b>
                Aby poznać cenę Twojego zamówienia, skontaktuj się z nami
                poprzez formularz kontaktowy, Facebooka lub e-mail.{" "}
              </b>
              <br></br>
              <hr></hr>
              <br></br>
              <div className="item-contact-buttons ">
                <Row>
                  <Col sm={6}>
                    <FontAwesomeIcon icon={faFileSignature} /> <br></br>
                    <Link to="/contact" className="link">
                      FORMULARZ KONTAKTOWY
                    </Link>{" "}
                  </Col>
                  <Col sm={6}>
                    <FontAwesomeIcon icon={faEnvelope} /> <br></br>
                    <Link to="mailto:printlypl@gmail.com" className="link">
                      EMAIL: PRINTLYPL@GMAIL.COM
                    </Link>{" "}
                  </Col>
                  <Col sm={6}>
                    <FontAwesomeIcon icon={faFacebookMessenger} />
                    <br></br>{" "}
                    <Link to="https://m.me/Printlypl" className="link">
                      FACEBOOK: WWW.FB.COM/PRINTLYPL
                    </Link>
                  </Col>
                  <Col sm={6}>
                    <FontAwesomeIcon icon={faPhone} />
                    <br></br>{" "}
                    <Link to="tel:575-254-057" className="link">
                      +48 575-254-057
                    </Link>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={7}>
            <div className="item-description">
              <h5 className="card-title mt-4">ULOTKI</h5>
              <br></br>
              <p className="card-text-2">
                <b>FORMATY ULOTEK </b> <br></br>W zależności od potrzeb i ilości
                treści, ulotki dostępne są w różnych formatach:
                <p>
                  <ul>
                    <li>
                      <b>A3 (297 × 420 mm)</b> –duży format, idealny na plakaty
                      informacyjne i reklamy o większej powierzchni graficznej.
                    </li>
                    <li>
                      <b>A4 (210 × 297 mm)</b> – klasyczna wielkość kartki
                      biurowej, często stosowana w katalogach, menu czy ofertach
                      promocyjnych.
                    </li>
                    <li>
                      <b>A5 (148 × 210 mm)</b> – kompaktowy, wygodny format,
                      popularny w dystrybucji reklam i materiałów
                      informacyjnych.
                    </li>
                    <li>
                      <b>A6 (105 × 148 mm)</b> – poręczna ulotka, często
                      stosowana do promocji wydarzeń, rabatów i kuponów.
                    </li>
                    <li>
                      <b>DL (99 × 210 mm) </b> – wąska i podłużna ulotka,
                      doskonała do wkładania do kopert, wykorzystywana w
                      marketingu bezpośrednim.
                    </li>
                  </ul>
                </p>
                <p>
                  <b>RODZAJE PAPIERU </b> <br></br>
                  Wybór odpowiedniego papieru wpływa na wygląd i trwałość
                  ulotki:
                  <ul>
                    <li>
                      <b>Papier kredowy </b> – dostępny w wersji matowej i
                      błyszczącej, zapewnia wysoką jakość druku i intensywne
                      kolory. Idealny do materiałów promocyjnych o estetycznym
                      wykończeniu.
                    </li>
                    <li>
                      <b>Papier offsetowy</b> –matowy, chłonny, świetnie nadaje
                      się do zadruku dwustronnego i pisania po ulotce
                      długopisem. Sprawdza się w formularzach, kartach
                      rabatowych czy ankietach.
                    </li>
                  </ul>
                </p>
                Ulotki są jednym z najskuteczniejszych narzędzi marketingowych.
                Sprawdzają się w reklamie produktów i usług, informowaniu o
                wydarzeniach, promocjach oraz ofertach specjalnych. Są chętnie
                wykorzystywane w gastronomii (menu, kupony rabatowe), kulturze
                (zaproszenia, programy imprez) i biznesie (informacje o firmie,
                instrukcje, katalogi). Dzięki swojej formie są łatwe do
                dystrybucji i docierają do szerokiego grona odbiorców.
              </p>

              <p className="card-text-2">
                <b>
                  W przypadku pytań prosimy o{" "}
                  <Link to="/contact" className="link">
                    kontakt
                  </Link>
                  . Dziękujemy za zainteresowanie naszą ofertą i mamy nadzieję,
                  że wspólnie znajdziemy idealne rozwiązanie dla Twoich potrzeb!
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Flyer;
