import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faFileSignature,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const BussinessCard = () => {
  return (
    <>
      <Helmet>
        <title>Wizytówki | Printly</title>
        <meta
          name="description"
          content="Zapoznaj się z ofertą wizytówek, oblicz cenę korzystając z kalkulatora."
        />
        <link rel="canonical" href="https://printly.net.pl/bussiness-card" />
      </Helmet>

      <div className="item">
        <Row>
          <Col md={5}>
            <img
              src="/img/bussiness-card.png"
              className="img-fluid"
              alt="baner"
            />
            <div className="item-contact text-center">
              <b>
                Aby poznać cenę Twojego zamówienia, skontaktuj się z nami
                poprzez formularz kontaktowy, Facebooka lub e-mail.{" "}
              </b>
              <br></br>
              <hr></hr>
              <br></br>
              <div className="item-contact-buttons ">
                <Row>
                  <Col sm={6}>
                    <FontAwesomeIcon icon={faFileSignature} /> <br></br>
                    <Link to="/contact" className="link">
                      FORMULARZ KONTAKTOWY
                    </Link>{" "}
                  </Col>
                  <Col sm={6}>
                    <FontAwesomeIcon icon={faEnvelope} /> <br></br>
                    <Link to="mailto:printlypl@gmail.com" className="link">
                      EMAIL: PRINTLYPL@GMAIL.COM
                    </Link>{" "}
                  </Col>
                  <Col sm={6}>
                    <FontAwesomeIcon icon={faFacebookMessenger} />
                    <br></br>{" "}
                    <Link to="https://m.me/Printlypl" className="link">
                      FACEBOOK: WWW.FB.COM/PRINTLYPL
                    </Link>
                  </Col>
                  <Col sm={6}>
                    <FontAwesomeIcon icon={faPhone} />
                    <br></br>{" "}
                    <Link to="tel:575-254-057" className="link">
                      +48 575-254-057
                    </Link>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={7}>
            <div className="item-description">
              <h5 className="card-title mt-4">WIZYTÓWKI</h5>
              <br></br>
              <p className="card-text-2">
                <b>FORMATY WIZYTÓWEK </b> <br></br>
                Wizytówki dostępne są w różnych formatach, dostosowanych do
                standardów obowiązujących w różnych krajach:
                <p>
                  <ul>
                    <li>
                      <b>50 × 90 mm</b> – standardowy format wizytówki w Polsce.
                    </li>
                    <li>
                      <b>55 × 85 mm</b> – popularny w Europie Zachodniej (tzw.
                      wymiar euro).
                    </li>
                    <li>
                      <b>55 × 90 mm</b> – najczęściej stosowany w krajach
                      skandynawskich.
                    </li>
                  </ul>
                </p>
                <p>
                  <b>RODZAJE WIZYTÓWEK </b> <br></br>
                  <ul>
                    <li>
                      <b>Jednostronne </b> – zadrukowana tylko jedna strona,
                      często wybierane w przypadku minimalistycznych projektów.
                    </li>
                    <li>
                      <b>Dwustronne</b> – większa przestrzeń na informacje,
                      możliwość umieszczenia dodatkowych elementów graficznych
                      lub treści.
                    </li>
                  </ul>
                </p>
                <p>
                  <b>WYBÓR PAPIERU </b> <br></br>
                  Oferujemy wizytówki drukowane na różnych rodzajach papieru:
                  <ul>
                    <li>
                      <b>Papier kredowy </b> – gładki i błyszczący, sprawia, że
                      kolory są bardziej wyraziste. Świetny do żywych grafik i
                      eleganckiego wykończenia.
                    </li>
                    <li>
                      <b>Papier satynowy</b> – bardziej matowy, przyjemny w
                      dotyku, nie odbija światła. Daje bardziej stonowany i
                      elegancki wygląd.
                    </li>
                  </ul>
                </p>
                Dobrze dobrany format, papier i wykończenie sprawią, że Twoja
                wizytówka będzie profesjonalna i trwała, idealnie
                odzwierciedlając wizerunek Twojej firmy. <br></br>
                <br></br>
                Wizytówki to nie tylko narzędzie do przekazywania danych
                kontaktowych, ale także skuteczny sposób budowania wizerunku
                firmy i relacji biznesowych. Są niezastąpione podczas spotkań
                networkingowych, targów czy konferencji, gdzie pomagają w
                nawiązywaniu nowych kontaktów. Mogą również pełnić funkcję kart
                rabatowych, lojalnościowych lub zaproszeń, dodając wartość
                użytkową. Dzięki odpowiedniemu projektowi i wykończeniu stanowią
                elegancki nośnik marki, który podkreśla profesjonalizm i dbałość
                o detale.
              </p>

              <p className="card-text-2">
                <b>
                  W przypadku pytań prosimy o{" "}
                  <Link to="/contact" className="link">
                    kontakt
                  </Link>
                  . Dziękujemy za zainteresowanie naszą ofertą i mamy nadzieję,
                  że wspólnie znajdziemy idealne rozwiązanie dla Twoich potrzeb!
                </b>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default BussinessCard;
